@media only screen and (min-width: 1400px) {
  .em-flex.pr-warning .fl-imag {
    width: 170px;
  }

  .em-appstoreCard {
    width: 32% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .datoin-signup .content-div.signup .all-content .h1-title {
    font-size: 25px;
  }

  .datoin-signup .content-div.signup .all-content .h1-title.isLearner {
    font-size: 25px;
  }

  .datoin-signup .content-div.signup .all-content .isLearner-title {
    font-size: 25px;
    margin: 20px 0 0;
  }

  .datoin-signup .content-div.signup .all-content .icon-signup-logo-div {
    padding: 25px 0 0;
  }

  .datoin-signup .content-div.signup .all-content .ul-list ul {
    padding: 20px 0 0;
  }

  .datoin-signup .content-div.signup .all-content .h3-helper {
    font-size: 18px;
  }

  .datoin-signup .content-div.signup .all-content .ul-list ul li {
    font-size: 18px;
  }

  .datoin-signup .content-div.signup .all-content .icon-signup-logo {
    height: 28px;
  }

  .em-appstoreCard {
    width: 31.5% !important;
  }

  .datoin-signup .main {
    width: 50% !important;
  }

  .project-root .spec-upload-doc {
    min-height: 140px !important;
  }

  .genAi-container .h2Line.congrats {
    font-size: 28px;
  }

  .popupMainContainer .ace_editor {
    height: calc(100vh - 275px) !important;
  }

}

@media only screen and (max-width: 1255px) {
  .em-appstoreCard {
    width: 30.5% !important;
  }

  .datoin-signup .main {
    width: 47% !important;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 767px) and (orientation: landscape) {}

@media only screen and (max-width: 1023px) {
  .em-appstoreCard {
    width: 48% !important;
  }

  .datoin-signup .main {
    width: 55% !important;
    margin: 10px 0;
  }

  .datoin-signup .content-div.signup .all-content .h1-title {
    font-size: 18px;
  }

  .datoin-signup .content-div.signup .all-content .h3-helper {
    font-size: 11px;
  }

  .datoin-signup .content-div.signup .all-content .ul-list ul li {
    font-size: 10px;
  }

  .datoin-signup .content-div.signup .all-content .ul-list ul {
    padding: 0px 0 0;
  }

  .datoin-signup .top {
    right: 4px;
    top: 4px;
  }

  .datoin-signup .already {
    color: #000;
    font-size: 9px;
    font-weight: 600;
    margin-right: 0;
    font-family: 'redHatMedium';
    z-index: 99;
  }

  .insights-expand-content {
    flex-direction: column !important;
  }
}

@media only screen and (min-width: 812px) and (max-width: 823px) {
  .datoin-signup .image-div {
    display: none;
  }

  .datoin-signup .emlyLogoMb {
    display: block;
    margin: 40px auto 10px;
  }
}

@media only screen and (max-width: 767px) {

  .mainDashboardConatiner .body-content {
    height: calc(100vh - 78px);
  }

  .datoin-signup .top {
    right: 10px;
  }

  .datoin-signup .image-div {
    width: 35%;
  }

  .datoin-signup .content-div {
    height: 100%;
  }

  .datoin-signup #emlyTandCiframe {
    padding: 15px 0px;
  }

  .datoin-signup .popupMainContainer .popupMainContainer-all-content .modalFooter-1 button {
    width: fit-content !important;
  }

  .datoin-signup .popupMainContainer-all-content.success {
    width: 85% !important;
  }

  .datoin-signup .contnet-main {
    padding-top: 70px;
  }

  .instance_down {
    width: 100%;
    margin: 0;
    height: auto;
    text-align: left;
  }

  .mainDashboardConatiner .right-body-panel {
    width: calc(100vw - 58px);
  }

  .current-project-list-parent .project-card {
    width: 100% !important;
  }

  .mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal {
    width: 95%;
  }

  .card-blank,
  .card {
    width: 100% !important;
  }

  .main-container {
    position: relative;
    width: 100%;
    top: 0;
    padding: 10px 0;
    float: right;
    right: 0;
    text-align: center;
    place-content: end;
    display: flex;
    align-content: flex-end;
  }

  .projectcreation {
    font-size: 16px;
  }

  .project-head-row {
    display: flex;
    flex-direction: column !important;
    z-index: 1;
    align-items: start !important;
    gap: 10px;
    margin: 10px 0 0 5px !important;
  }

  .project-head {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 10px;
    width: 70%;
    overflow: hidden;
  }

  .mainDashboardConatiner .LeftSidePanel .nav-item {
    width: 100% !important;
  }

  .mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .button-part .button-yes {
    padding: 4px;
    font-size: 10px !important;
  }

  .mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .button-part .button-no {
    padding: 4px;
    font-size: 10px !important;
  }

  .mainDashboardConatiner .right-body-panel .modalConatiner .switch-modal .parent-switch .heading-part {
    font-size: 12px !important;
  }

  .em-appstoreCard {
    width: 100% !important;
  }


  .nav-item.version .ver-2 {
    display: none;
  }

  .nav-item.version .ver-1 {
    margin-left: -10px;
  }

  .e-ccard {
    width: 100% !important;
  }

  .em-row.cbody .e-members-section {
    align-items: end !important;
    flex-direction: column-reverse !important;
    align-items: center !important;
    gap: 10px !important;
  }

  .ds-comp .datasets .section-one.full .datset-parent-list .dataset-item {
    width: 100% !important;
  }

  .showFlex {
    flex-direction: column !important;
  }

  .project-root .spec-body {
    flex-direction: column !important;
  }

  .project-root .spec-body-todos {
    width: 100% !important;
  }

  .project-root .spec-body-doc {
    width: 100% !important;
  }

  .project-root .content-inner {
    flex-wrap: wrap !important;
  }

  .ds-comp .header-action-panel .icon-Img.cdset {
    display: none !important;
  }

  .ds-flex-style {
    flex-direction: column !important;
  }

  .ds-flex-style .datasets {
    width: 100% !important;
  }

  .datoin-signup .main {
    width: 65% !important;
  }

  .datoin-signup .image-div {
    display: none;
  }

  .datoin-signup .Text-component {
    width: 100% !important;
  }

  .datoin-signup .learner-label-title {
    font-size: 14px;
  }

  .datoin-signup .emlyLogoMb {
    display: block;
    margin: 40px auto 10px;
  }

  .topicons {
    align-items: center !important;
    flex-direction: column-reverse !important;
    align-items: end !important;
  }

  .welcome-user .sec1 {
    width: 100% !important;
  }

  .welcome-user .sec2 {
    display: none;
  }

  .welcome-user .W-header,
  .welcome-user .W-header span {
    font-size: 24px;
    margin: 20px 0;
  }

  .welcome-user h3 {
    font-size: 20px;
  }

  .create-project-entry {
    display: flex;
    justify-content: center;
    width: 100% !important;
    flex-direction: column;
    transform: none;
    align-items: center !important;
  }

  .create-project-entry img.start-up-svg {
    max-width: 150px !important;
  }

  .pr-switch-avail {
    display: none;
  }

  .kabab-container-leftChild {
    display: none;
  }

  .kabab-container-main {
    justify-content: flex-end;
    margin: 15px 0 0;
  }

  .em-btn.project-notes {
    display: none !important;
  }

  .project-root .spec-circle {
    display: none !important;
  }

  .project-root .outer-spec-body {
    flex-direction: column !important;
  }

  .project-root .spec-body-todos-content {
    flex-direction: column !important;
  }

  .project-root .spec-body-todos-content .todos-preview {
    width: 100% !important;
  }

  .project-root .spec-body-todos-content .spec-sec-action {
    width: 100% !important;
  }

  .project-root .outer-spec-body {
    width: 98% !important;
  }

}

@media only screen and (max-width: 599px) {
  .datoin-signup .main {
    width: 90% !important;
  }

  .notifications-top {
    display: none !important;
  }

  .tp-notfiy {
    display: none;
  }

  .projectHTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
  }

  .profile-icons-div {
    position: absolute;
    right: 0;
    top: 10px;
  }

  .popupMainContainer-all-content {
    width: 90% !important;
  }

  .keycloackerror {
    width: 90% !important;
  }

  .keycloackerror .em-flex {
    width: 100% !important;
  }

  .keycloackerror .em-flex h1 {
    font-size: 18px !important;
  }

  .keycloackerror h2,
  .keycloackerror h3 {
    font-size: 20px;
  }

  .search-container,
  .ltst-line,
  .ltst-Help {
    display: none !important;
  }

  .header-action-panel {
    padding: 0;
  }

  .mainDashboardConatiner .right-body-panel .datoinTable .datoinTable-row {
    flex-direction: column !important;
  }

  .mainDashboardConatiner .right-body-panel .datoinTable .datoinTable-row .datoinTable-Header-Cell {
    display: none !important;
  }

  .mainItemView {
    width: 160px !important;
  }

  .create-project-button {
    width: fit-content !important;
    font-size: 8px !important;
    height: 29px !important;
    padding-top: 3px !important;
    padding-top: 8px !important;
    flex-direction: row;
  }

  .create-project-button .fa {
    display: none !important;
  }
}

@media only screen and (min-width: 450px) and (max-width: 450px) {}

@media only screen and (max-width: 479px) {
  .mainItemView {
    width: 155px !important;
  }
}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 367px) {}

@media only screen and (max-width: 359px) {}

@media only screen and (max-width: 290px) {}